import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useState } from 'react';
import { soapcall } from './webservice_call';
import './Home.css'

const Home = ({ userData }) => {
    console.log(userData)
    console.log(userData.attributes['custom:UserID'])
    const soapMethods = [
        { "name": "getAll", "url": "getAll" },
        { "name": "getAllWithEncoding", "url": "getAllWithEncoding" },
        { "name": "getAssociatedAlternativeProductFamilies", "url": "getAssociatedAlternativeProductFamilies" },
        { "name": "getAssociatedAlternativeProductFamiliesWithEncoding", "url": "getAssociatedAlternativeProductFamiliesWithEncoding" },
        { "name": "getAssociatedInstruments", "url": "getAssociatedInstruments" },
        { "name": "getAssociatedLocations", "url": "getAssociatedLocations" },
        { "name": "getAssociatedProductFamilies", "url": "getAssociatedProductFamilies" },
        { "name": "getFeatureProperties", "url": "getFeatureProperties" },
        { "name": "getFeatures", "url": "getFeatures" },
        { "name": "syncUserFromCDCFromWebhook", "url": "syncUserFromCDCFromWebhook" },
        { "name": "syncUserToOrthoconnect", "url": "syncUserToOrthoconnect" },
        { "name": "syncUserToOrthoconnectNoReturnMessage", "url": "syncUserToOrthoconnectNoReturnMessage" },

    ];

    const [api_reponse, setApiResponse] = useState('');
    const [selectedMethod, setSelectedMethod] = useState('');
    const [userId, setUserId] = useState('');

    const setSelectedSoapMethod = (e) => {
        setSelectedMethod(e.target.value);
    }

    const setChangedUserId = (e) => {
        setUserId(e.target.value);
    }

    const getSoapResponse = () => {
        soapcall({}, {"method": selectedMethod, "userid": userData.attributes['custom:UserID']}).then((res) => {
            console.log("respo*********************", res);
            const pretty = JSON.stringify(res.data, undefined, 2);
            setApiResponse(pretty);
        })
        .catch((err) => {
            console.error("error occured $$$$ ", err);
        });
    }

    console.log('asdfasdfasdfasdf')
    return (
        <>
            <div className="main-content">
                <div className='header-logo'>
                    <div className='header-text'>Webservice Response App</div>
                </div>
                <div className='content-div'>
                    <FormControl fullWidth>
                        <InputLabel>Select Method</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedMethod}
                            label="Select Method"
                            onChange={setSelectedSoapMethod}
                        >
                        
                            { soapMethods.map((method, index) => {
                                            return (
                                                    <MenuItem key={index} value={method.url}>{method.name}</MenuItem>
                                            )
                                        })
                                    }
                        </Select>
                    </FormControl>
                </div>

                {/* <div>
                    <TextField onChange={setChangedUserId} defaultValue={userData.attributes['custom:UserID']}  fullWidth id="outlined-basic" label="User Id" variant="outlined" />
                    
                </div> */}
                <div>
                    <Button onClick={getSoapResponse} variant="contained">Get Response</Button>
                </div>

                

                <div className='content-div'>
                <div>Response</div>
                            <pre>{api_reponse}</pre>
                </div>
            </div>
        </>
    )
}

export default Home