import { Auth } from 'aws-amplify';
import axios from 'axios';

export function soapcall(headers = { "content-type": "application/json" }, params = {}) {
    console.log("params###########", params)
    return get('soapcall',headers, params);
}


export default async function get (url, headers = { "content-type": "application/json" }, params = {}) {

    const ORTHO_CONNECT_API_GATEWAY = process.env.REACT_APP_ORTHO_CONNECT_API;
    let accessToken;
    let jwt;
    let cognitoSession;

    console.log("final params", params);

    await Auth.currentSession().then((res) => {
        accessToken = res.getAccessToken();

        jwt = res.getIdToken().getJwtToken();
        cognitoSession = res;
    });

    if (cognitoSession.isValid()) {
        headers.authorization = "Bearer " + jwt;
    } else {
        Auth.federatedSignIn();
        return "";
    }

    return axios.get(ORTHO_CONNECT_API_GATEWAY + url, { headers, params });

}