import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import './Nav.css';
import { redirectToCognitoLogout } from '../../../utils/auth-utilities'
import { Auth } from 'aws-amplify';
import { Button } from '@material-ui/core';

const $ = global.$;

export default class Nav extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        $('nav ul li a:not(:only-child)').click(function (e) {
            $(this).siblings('.nav-dropdown').toggle();
            // Close one dropdown when selecting another
            $('.nav-dropdown').not($(this).siblings()).hide();
            e.stopPropagation();
        });
        // Clicking away from dropdown will remove the dropdown class
        $('html').click(function () {
            $('.nav-dropdown').hide();
        });
        // Toggle open and close nav styles on click
        $('#nav-toggle').click(function () {
            $('nav ul').slideToggle();
        });
        // Hamburger to X toggle
        $('#nav-toggle').on('click', function () {
            this.classList.toggle('active');
        });
    }

    render() {
        return (
            <React.Fragment>
                <section className="navigation">
                    <div className="nav-container">
                        <div className="brand">
                            <a href="/">Ortho Clinical Diagnostics</a>
                        </div>
                        <div className="nav-list">
                            <nav>
                                <div className="nav-mobile"><a id="nav-toggle" href="#!"><span></span></a></div>
                                <ul className="nav-list">
                                    {/* <li>
                                    <NavLink className="navbar-item" activeClassName="is-active" to="/">
                                        Home
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="navbar-item" activeClassName="is-active" to="/imagery">
                                        Imagery
                                    </NavLink>
                                </li>
                                <li>
                                    <a href="#!">Transfusion Medicine</a>
                                    <ul className="nav-dropdown">
                                        <li>
                                            <NavLink className="navbar-item" activeClassName="is-active" to="/imagery">
                                                Image Count By Day
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink className="navbar-item" activeClassName="is-active" to="/userGuide">
                                                Image Count By Hour
                                            </NavLink>
                                        </li>
                                        <li>
                                            <a href="#!">Run Time</a>
                                        </li>
                                    </ul>
                                </li> */}
                                    {/* <li>
                                        <NavLink className="navbar-item" activeClassName="is-active" to="/userGuide">
                                            User Guide
                                    </NavLink>
                                    </li> */}
                                    <li>
                                        <Link className="navbar-item" activeClassName="is-active" to="/imageryNew">
                                            Imagery
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="navbar-item" activeClassName="is-active" to="/orthoConnectTest">
                                            Otho Connect API
                                        </Link>
                                    </li>
                                    <li>
                                        <Button variant="outlined" onClick={() => Auth.signOut()}>Logout</Button>
                                    </li>
                                    {/* <li>
                                        <a href="#!">Contact</a>
                                    </li> */}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}